<div class="card">
    <div class="header-wallet">
        <h3>{{ item.name }}</h3>
    </div>
    <div class="body-wallet">
        <img 
            *ngIf="item.avatar" 
            [src]="item.avatar" 
            alt="foto de perfil" 
            title="foto de perfil" 
        />
    
        <img 
            *ngIf="!item.avatar" 
            src="../../../assets/img/profile-pic.png" 
            alt="foto de perfil" 
            title="foto de perfil" 
        />
    
        <h5>{{ item.userName }}</h5>
        
        <div class="numberWallets">
            <span class="material-icons md-24 graycolor">transfer_within_a_station</span>
            <p>{{ item.total || 0 }}</p>
        </div>
    </div>
    <div class="container-options">
        <button *ngIf="showUpdateItemButton" (click)="navigate(item.id)" class="edit-planned">Editar</button>
    </div>
</div>