import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  serverMode: boolean = !window.location.href.includes("localhost");

  production: boolean = environment.production;
  smartPerformanceApi: string = this.serverMode ? environment.smartPerformanceApiServer : environment.smartPerformanceApiLocal;
  apiUrl: string = this.serverMode ? environment.smartPerformanceApiServer : environment.smartPerformanceApiLocal;
}