import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public isLogged = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) { }

  newLogin(data): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/login`, data, this.getHeader());
  }

  getWorkGroup(): Observable<any>{
    return this.http.get(`${this.environment.smartPerformanceApi}/work_groups`, this.getHeader());
  }

  logout(): Observable<any>{
    return this.http.post(`${this.environment.smartPerformanceApi}/logout`, this.getHeader());
  }

  public getToken(): string {
    return localStorage.getItem('workgroup');
  }

  public setIsLogged(value: boolean): void {
    this.isLogged.emit(value);
  }

  getHeader(): any{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "x-access-token":  "jl9QcdPRbFRml5s7FNyjNLJLkPa7hiagSSZDjGZjnNR964ub5tRmGRg75Q1bc9I4"
    });

    return headers;
  }

}