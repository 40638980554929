<div class="modal-header">
    <h4 class="modal-title">Adicionar Cliente</h4>

    <button 
      type="button" 
      class="btn-close" 
      aria-label="Close" 
      (click)="setCloseModal(false)"
    ></button>
</div>

<div class="modal-body">
    <div class="item" [formGroup]="OutletSearch">
        <div class="input-group mb-3">
            <input
                type="text"
                class="form-control"
                placeholder="Digite o nome do cliente" 
                formControlName="search"
                (keydown.enter)="searchItem()"
            />

            <div class="input-group-append">
                <button (click)="searchItem()" class="btn btn-primary" type="button">Pesquisar</button>
              </div>
        </div>
    </div>

    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">CNPJ</th>
                <th scope="col">Nome Fantasia</th>
                <th scope="col">Razão Social</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of clientList | paginate: { id:'paginateClientList', itemsPerPage: pageControl.perPage, currentPage: pageControl.page, totalItems: clientList.length}">
                <td>
                    <input 
                        type="checkbox"
                        [checked]="outletListSelected.includes(item.pdv_id)"
                        (click)="addClient(item)"
                    />
                </td>
                <td>{{ item.cnpj }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.reason }}</td>
            </tr>
        </tbody>
    </table>

    <div class="finishTable">
        <h3>Total: {{ clientList.length }}</h3>
        <div class="paginator">
            <div class="paginator">
                <pagination-controls 
                    id="paginateClientList" 
                    (pageChange)="changePage($event)"
                    (pageChange)="paginateClient.page = $event"
                    nextLabel="Próximo" 
                    previousLabel="Anterior"
                >
                </pagination-controls>
            </div>
        </div>
        
        <button class="btn btn-primary" title="Fechar Janela" (click)="save()">
            Adicionar
        </button>
    </div>
</div>