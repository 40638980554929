<div class="container-filter">
  <form [formGroup]="FormDefault" (ngSubmit)="onSubmit()">
    <div class="filters">
      <div class="filtro" *ngIf="filterData.filters.coordinators">
        <label>Coordenador</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="coordinator"
            class="fields"
            formControlName="coordinators"
          >
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.coordinators">
              <option [value]="item.user_id">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.coordinators.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro" *ngIf="filterData.filters.supervisors">
        <label>Supervisor</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="supervisor"
            class="fields"
            formControlName="supervisors"
            #supervisor
            (change)="onChangeHierarchies('supervisors', 'promoters')"
          >
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.supervisors">
              <option [value]="item.user_id">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.supervisors.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro" *ngIf="filterData.filters.supervisorCommercial">
        <label>Supervisor Comercial</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="supervisor"
            class="fields"
            formControlName="supervisorCommercial"
            #supervisor
            (change)="onChangeHierarchies('supervisorCommercial', 'sellers')"
          >
            <option value="">Todos</option>
            <ng-container
              *ngFor="let item of filterData.filters.supervisorCommercial"
            >
              <option [value]="item.user_id">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.supervisorCommercial.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro" *ngIf="filterData.filters.promoters">
        <label>Promotor</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="promoter"
            class="fields"
            formControlName="promoters"
            #promoter
            (change)="onChangePromoters()"
          >
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.promoters">
              <option [value]="item.user_id">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.promoters.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>
      <div class="filtro" *ngIf="filterData.filters.sellers">
        <label>Vendedor</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="sellers"
            class="fields"
            formControlName="sellers"
            #promoter
          >
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.sellers">
              <option [value]="item.user_id">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.sellers.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro" *ngIf="isEnabledUf">
        <label>UF</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="uf"
            class="fields"
            formControlName="uf"
            (change)="onChangeUf()"
          >
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.ufs">
              <option [value]="item.code">{{ item.code }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.ufs.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div
        class="filtro"
        *ngIf="filterData.filters.networks && includesMarkets"
      >
        <label>Rede</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="network"
            class="fields"
            formControlName="network"
            #network
            (change)="onChangeNetwork()"
          >
            <option value="all">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.networks">
              <option [value]="item.name">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.networks.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>
      <div class="filtro" *ngIf="filterData.filters.markets && includesMarkets">
        <label>Lojas</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select name="market" class="fields" formControlName="market">
            <option value="all">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.markets">
              <option [value]="item.pdv_id">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.markets.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro" *ngIf="isEnabledCategory">
        <label>Categoria</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            name="category"
            class="fields"
            formControlName="category"
            (change)="onChangeCategory()"
          >
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.categories">
              <option [value]="item.name">{{ item.name }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.categories.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div
        class="filtro"
        *ngIf="includesProducts && filterData.filters.products"
      >
        <label>Produtos</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select name="product" class="fields" formControlName="product">
            <option value="">Todos</option>
            <ng-container *ngFor="let item of filterData.filters.products">
              <option [value]="item.description">{{ item.description }}</option>
            </ng-container>
          </select>
          <span class="select-range"
            >0 / {{ filterData.filters.products.length }}</span
          >
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro">
        <label>Filtrar por</label>
        <div class="field-select" *ngIf="!loadingFilters">
          <select
            id="periodo"
            class="fields"
            formControlName="periods"
            (change)="changeType()"
          >
            <option value="">Selecione</option>
            <option id="dia" value="dia">Dias</option>
            <option id="periodos" value="periodos">Período</option>
          </select>
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
        <div class="loadingMaterial" *ngIf="loadingFilters">
          <div
            class="spinner-border spinner-border-sm text-secondary"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p>Carregando</p>
        </div>
      </div>

      <div class="filtro" *ngIf="periodoFilter">
        <label for="data">Data Início</label>
        <input
          type="date"
          id="data"
          class="campos"
          formControlName="startDate"
          max="{{ dateActually | date : 'yyyy-MM-dd' }}"
        />
      </div>
      <div class="filtro" *ngIf="periodoFilter">
        <label for="data">Data Final</label>
        <input
          type="date"
          id="data"
          class="campos"
          formControlName="finishDate"
          max="{{ dateActually | date : 'yyyy-MM-dd' }}"
        />
      </div>
      <div class="filtro" *ngIf="dateFilter">
        <label>Data</label>
        <div class="field-select">
          <select
            name=""
            id="periodo"
            class="fields"
            formControlName="dataPeriodo"
          >
            <option value="">Selecione</option>
            <option value="hoje">Hoje</option>
            <option value="ontem">Ontem</option>
            <option value="ultimosseven">Últimos 7 dias</option>
            <option value="ultimosdias">Últimos 30 dias</option>
          </select>
          <img
            class="select-icon"
            src="../../../assets/icons/arrowDown.svg"
            alt="Ícone seta para baixo"
          />
        </div>
      </div>

      <div class="filtro">
        <button type="submit">
          <span class="material-icons">search</span>
          Procurar
        </button>
      </div>
    </div>
  </form>
</div>
