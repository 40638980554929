import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class PdvsService {

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) { }

  private getHeader(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "x-access-token": "jl9QcdPRbFRml5s7FNyjNLJLkPa7hiagSSZDjGZjnNR964ub5tRmGRg75Q1bc9I4"
    });
    return headers;
  }

  getPDVs(): Observable<any> {
    return this.http.get(`${this.environment.smartPerformanceApi}/pdv/data`, this.getHeader());
  }

  createPDV(data): Observable<any> {
    return this.http.post(`${this.environment.smartPerformanceApi}/pdvs/import`, data, this.getHeader());
  }

  getAddressUsignCep(data): Observable<any> {
    return this.http.post(`${this.environment.smartPerformanceApi}/address/zip_code`, data, this.getHeader());
  }

  deleteAllPDVs(data): Observable<any> {
    return this.http.post(`${this.environment.smartPerformanceApi}/pdvs/remove/all`, data, this.getHeader());
  }
}
