import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WalletService } from '../../wallet.service';

@Component({
  selector: 'app-remove-wallet',
  templateUrl: './remove-wallet.component.html',
  styleUrls: ['./remove-wallet.component.scss']
})
export class RemoveWalletComponent {

  constructor(
    private walletService: WalletService,
    private activeModal: NgbActiveModal
  ) { }
  
  @Input() itemId: string = "";
  loadingDelete: boolean = false;

  removeItems(){
    this.loadingDelete = true;

    this.walletService.deleteItem(this.itemId).subscribe(response => {
      if(response){
        this.loadingDelete = false;
        this.setCloseModal(true);
      }
    })
  }

  setCloseModal(result: boolean){
    this.activeModal.close({
      success: result
    })
  }

}
