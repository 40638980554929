import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Util } from 'src/app/shared/util';
import { UsersService } from '../services/users/users.service';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private usersService: UsersService
  ) {}
  //toda minha requisiscao passa pelo intercept
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = JSON.parse(localStorage.getItem('minalba:user'));
    request = request.clone({
      setHeaders: {
        // 'Authorization': `Bearer ${user && user.access_token}`,
        "x-access-token"  : "jl9QcdPRbFRml5s7FNyjNLJLkPa7hiagSSZDjGZjnNR964ub5tRmGRg75Q1bc9I4",
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
    //quando  da bom
    return next.handle(request).pipe( tap((response) => {
      // debugger
    },
      //quando da erro
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 ) {
          Util.logout();
          this.router.navigate(['/login']);
          this.usersService.setIsLogged(false);
          return;
        }
      }
    }));
  }
}
