import { CookieService } from "ngx-cookie-service";

export class Util {
    // static cookieService: CookieService;
    static windowReload(): void {
      window.location.reload();
    }
  
    static verifyLogged() {
      // const user = JSON.parse(localStorage.getItem('minalba:user'));
      const user = JSON.parse(localStorage.getItem('workgroup'));
      if (user ) {
        return true;
      }
      // if (user ) {
      //   return true;
      // }
  
      return false;
    }
  
    static validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  
    static logout() {
      localStorage.removeItem('workgroup');
      localStorage.removeItem('personal_info');
      localStorage.removeItem('detailvisits');
      // this.cookieService.deleteAll();
    }
  }