import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../environment/environment.service';

@Injectable({
    providedIn: 'root'
  })
  export class ExportService {
  
    constructor(
      private http: HttpClient,
      private environment: EnvironmentService
    ) { }
  
    exportTable(data = ''): Observable<any> {
  
      return this.http.post(`${this.environment.smartPerformanceApi}/documents/export/excel`, data, { headers: this.getHeader(), responseType: "blob" });
    }

    exportTxt(data = ''): Observable<any> {
  
      return this.http.post(`${this.environment.smartPerformanceApi}/documents/export/txt`, data, { headers: this.getHeader(), responseType: "blob" });
    }
  
    exportPdf(data = ""): Observable<any> {
  
      return this.http.post(`${this.environment.smartPerformanceApi}/documents/export/pdf`, data, { headers: this.getHeader(), responseType: "blob" });
    }
  
    getHeader(): any {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        "x-access-token": "jl9QcdPRbFRml5s7FNyjNLJLkPa7hiagSSZDjGZjnNR964ub5tRmGRg75Q1bc9I4"
      });
  
      return headers;
    }

    
  
  
  }
  