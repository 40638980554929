import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PdvsService } from 'src/app/core/services/pdvs/pdvs.service';
import { FilterSearch } from 'src/app/utils/searchItens';
// import { PaginateInterface } from 'src/app/base/paginate.interface';
// import { ClientInterface } from 'src/app/core/client/client.interface';
// import { ClientService } from 'src/app/core/client/client.service';

@Component({
  selector: 'app-modal-add-client',
  templateUrl: './modal-add-client.component.html',
  styleUrls: ['./modal-add-client.component.scss']
})
export class ModalAddClientComponent implements OnInit {

  constructor(
    private clientService: PdvsService,
    private activeModal: NgbActiveModal
  ) { }

  @Input() outletListSelected: any[] = [];
  clientListData: any[] = [];

  paginateClient: any = {
    page: 1,
    perPage: 10,
    countPage: 1,
    sortBy: "tradeName",
    sort: "asc",
    total: 10,
    items: []
  };
  
  OutletSearch = new FormGroup({
    search: new FormControl("")
  })

  query: Record<string, string> = {};
  search: string = "";

  clientList: any[] = [];
  clientListBackup: any[] = [];

  pageControl = {
    perPage: 10,
    page: 1
  }
  
  ngOnInit(): void {
    this.getClient();
  }

  getClient(){
    this.clientService.getPDVs().subscribe(response => {
      this.clientList = response.table_data;
      this.clientListBackup = response.table_data;
    })
  }

  addClient(item: any){
    if(!this.outletListSelected.includes(item.pdv_id)){
      this.outletListSelected.push(item.pdv_id);
      this.clientListData.push(item);
    } else {
      const findIndexSelected = this.outletListSelected.findIndex(element => element == item.pdv_id);
      const findIndexData = this.clientListData.findIndex(element => element.id == item.pdv_id);

      this.outletListSelected.splice(findIndexSelected, 1);
      this.clientListData.splice(findIndexData, 1);
    }
  }

  setCloseModal(response: boolean, listItems?: any[], listItemsSelected?: string[]){
    this.activeModal.close({
      success: response, 
      listItems,
      listItemsSelected
    });
  }

  save(){
    this.setCloseModal(true, this.clientListData, this.outletListSelected)
  }

  changePage(page: number){
    this.pageControl.page = page;
  }

  searchItem(){
    const { search } = this.OutletSearch.value;
    this.clientList = new FilterSearch().search(this.clientListBackup, search, "search");
  }
}
