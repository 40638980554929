import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UpdateWalletPage } from "./update-wallet.page";

const routes: Routes = [
  {path: "", component: UpdateWalletPage},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdateWalletRoutingModule { }
