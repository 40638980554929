import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { UpdateWalletModule } from "./features/tool/wallet/page/update-wallet/update-wallet.module";
import { WalletModule } from "./features/tool/wallet/wallet.module";

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'login',
        loadChildren: () =>
          import('./features/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'home',
        loadChildren: () =>
          import('./features/home/home.module').then((m) => m.HomeModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'splash',
        loadChildren: () =>
          import('./features/splash/splash.module').then((m) => m.SplashModule),
    },
    {
        path: 'produtividade',
        loadChildren: () =>
          import('./features/productivity/productivity.module').then((m) => m.ProductivityModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'visitas',
        loadChildren: () =>
          import('./features/visit/visit.module').then((m) => m.VisitModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'rotina-de-trabalho',
        loadChildren: () =>
          import('./features/work-routine/work-routine.module').then((m) => m.WorkRoutineModule),
          canActivate: [AuthGuard]
    },
    {
      path: 'cartao-ponto',
      loadChildren: () =>
        import('./features/timecard/timecard.module').then((m) => m.TimecardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'preco',
        loadChildren: () =>
          import('./features/price/price.module').then((m) => m.PriceModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'validade',
        loadChildren: () =>
          import('./features/validate/validity.module').then((m) => m.ValidityModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'rupturas',
        loadChildren: () =>
          import('./features/break/break.module').then((m) => m.BreakModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'concorrentes',
        loadChildren: () =>
          import('./features/competitor/competitor.module').then((m) => m.CompetitorModule),
          canActivate: [AuthGuard]
    },
    {
        path: 'estoque',
        loadChildren: () =>
          import('./features/stock/stock.module').then((m) => m.StockModule),
          canActivate: [AuthGuard]
    },
    {
      path: 'manipulacao',
      loadChildren: () =>
        import('./features/manipulation/manipulation.module').then((m) => m.ManipulationModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'mpdv',
      loadChildren: () =>
        import('./features/mpdv/mpdv.module').then((m) => m.MpdvModule),
        canActivate: [AuthGuard]
    },
    {
      path: 'ferramentas',
      loadChildren: () => 
        import('./features/tool/tool.module').then((m) => m.ToolModule),
        canActivate: [AuthGuard]
    },
    {
      path: "ferramentas/carteiras",
      loadChildren: () => 
      import("./features/tool/wallet/wallet.module").then((m) => m.WalletModule),
      canActivate: [AuthGuard]
    },
    {
      path: "ferramentas/carteiras/edicao/:id",
      loadChildren: () => 
      import("./features/tool/wallet/page/update-wallet/update-wallet.module").then((m) => m.UpdateWalletModule),
      canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}