import { ButtonExcelComponent } from './button-excel/button-excel.component';
import { FilterComponentComponent } from './filter-component/filter-component.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeadersIndicatorsColorsComponent } from './headers-indicators-colors/headers-indicators-colors.component';
import { AgmCoreModule } from '@agm/core';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DefaultHeaderIndicatorComponent } from './default-header-indicator/default-header-indicator.component';
import { LoadingCircleComponent } from './loading-circle/loading-circle.component';
import { QuantityAmountComponent } from './quantity-amount/quantity-amount.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ModalComponent,
    HeadersIndicatorsColorsComponent,
    FilterComponentComponent,
    ButtonExcelComponent,
    DefaultHeaderIndicatorComponent,
    SearchBarComponent,
    LoadingCircleComponent,
    QuantityAmountComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAB3sd8hSP8jNeFJTsPSPNkdy149KA-dfA'
    }),
  ],
  exports: [
    BreadcrumbComponent,
    ModalComponent,
    HeadersIndicatorsColorsComponent,
    FilterComponentComponent,
    ButtonExcelComponent,
    DefaultHeaderIndicatorComponent,
    SearchBarComponent,
    LoadingCircleComponent,
    QuantityAmountComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ComponentsDefaultModule { }
