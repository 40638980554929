import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PdvsService } from "src/app/core/services/pdvs/pdvs.service";
import { RemoveWalletComponent } from "../../components/remove-wallet/remove-wallet.component";
import { WalletInterfaceReading } from "../../wallet.interface";
import { WalletService } from "../../wallet.service";
import { ModalAddClientComponent } from "./components/modal-add-client/modal-add-client.component";

@Component({
	selector: "app-updateWallet",
	templateUrl: "./update-wallet.page.html",
	styleUrls: ["./update-wallet.page.scss"]
})
export class UpdateWalletPage implements OnInit {

	constructor(
    private walletService: WalletService,
    private activateRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  fastNavigationData = [
    {
      path: '/home',
      name: 'Home',
    },
    {
      path: '/ferramentas',
      name: 'Ferramentas',
    },
    {
      path: '/ferramentas/carteiras',
      name: 'Carteira',
    },
    {
      path: '/ferramentas/cateiras/edicao',
      name: 'Edição Carteira',
    }
  ];

  wallet: WalletInterfaceReading = {
    avatar: "",
    name: "",
    totalWallet: 0,
    userId: "",
    userName: "",
    total: [],
    id: "",
    clientList: []
  };

  clientSearch: any[] = [];
  loading: boolean = false;
  editName: boolean = false;
  showButtonDelete: boolean = false;
  page: any = "";

  itemSort: any = {
    cnpj: { sort: true },
    tradeName: { sort: true },
    companyName: { sort: true },
  }

  WalletForm = new FormGroup({
    name: new FormControl("")
  })

  ngOnInit(): void {
    this.loading = true;
    this.activateRoute.params.subscribe(response => {
      if(response.id){
        this.getItem(response.id);
      }
    });
  }

  getItem(id: string){
    this.walletService.findItem(id).subscribe(response => {
      if(response){
        this.wallet = response;
        
        if(response.clientList && response.clientList.length && Object.values(response.clientList)[0].id !== undefined){
          this.clientSearch = response.clientList;
        }

        this.loading = false;
      }
    })
  }

  getOutletModal(){    
    const modalRef = this.modalService.open(ModalAddClientComponent, {
      size: "lg"
    });

    if(!this.wallet.clientIds.length){
      this.wallet.clientList = [];
    }

    modalRef.componentInstance.outletListSelected = this.wallet.clientIds;

    modalRef.result.then(response => {
      if(response.success){
        const { listItemsSelected, listItems } = response;

        listItemsSelected.forEach((item: string) => {
          if(!this.wallet.clientIds.includes(item)){
            this.wallet.clientIds.push(item);
          }
        });

        listItems.forEach((item: any) => {
          if(!this.wallet.clientList.find(element => element.id === item.id)){
            this.wallet.clientList.push({
              tradeName: item.name,
              cnpj: item.cnpj,
              companyName: item.reason,
              id: item.pdv_id
            });
          }

          if(!this.clientSearch.find(element => element.id === item.id)){
            this.clientSearch.push();
          }
        });
      }
    })
  }

  removeClient(id: string){
    const indexClientList = this.wallet.clientList.findIndex((element => element.id === id));
    const indexClientIds = this.wallet.clientIds.findIndex((element => element === id));

    this.wallet.clientList.splice(indexClientList, 1);
    this.wallet.clientIds.splice(indexClientIds, 1);
  }

  updateItem(){
    const { name } = this.WalletForm.value;

    this.walletService.updateItem(this.wallet.id!, { name, clientIds: this.wallet.clientIds }).subscribe(response => {
      if(response){
        this.toastrService.success("Atualizado com sucesso", "Sucesso", { timeOut: 4000 });
      }
    })
  }

  removeItem(id: string){
    const modalRef = this.modalService.open(RemoveWalletComponent, {
      size: "x1"
    })

    modalRef.componentInstance.itemId = id;

    modalRef.result.then((result) => {
      if(result.success){
        this.router.navigate(["ferramentas/carteiras"])
        this.toastrService.success("Removido", "Sucesso", { timeOut: 4000})    
      }
    })
  }

  editNameField(){
    this.editName = !this.editName;

    if(this.editName){
      this.WalletForm.get("name")?.setValue(this.wallet.name);
    }

    if(!this.editName){
      this.wallet.name = this.WalletForm.get("name")?.value;
    }

    if(!this.WalletForm.get("name")?.value){
      this.wallet.name = "Sem Nome"
    }
  }

  searchList(listSearch: any[]){
    console.log(listSearch);
    //this.clientSearch = listSearch;
  }

  search(text: string){
    if(!text){
      //this.clientSearch = this.wallet.clientList;
    }
  }

  sortItems(field: string) {
    // this.itemSort[field] = { ...this.itemSort[field], sort: !this.itemSort[field].sort }

    // this.wallet.clientList.sort((a: any, b: any,) => {
    //   return CompareNames(a[field], b[field], !this.itemSort[field].sort);
    // });
  }
}
