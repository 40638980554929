import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FastNavigationComponent } from './fast-navigation.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule
    ],
    exports: [FastNavigationComponent],
    declarations: [FastNavigationComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class FastNavigationModule { }
