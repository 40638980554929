import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from '../shared/directives/clickoutside/click-outside.directive';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { NavComponent } from './layout/nav/nav.component';
// import { ClickOutsideDirective } from '../shared/directives/click-outside/click-outside.directive';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [ NavComponent, MainLayoutComponent, ClickOutsideDirective],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule
  ],
  exports: [MainLayoutComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' }   
  ]
})
export class CoreModule {}
