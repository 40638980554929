import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UsersWithoutWalletInterface, WalletInterfaceCreate, WalletInterfaceReading } from "./wallet.interface";

@Injectable()
export class WalletService {
	constructor(
    private http: HttpClient
  ) { }

  route = "wallet";

  private getHeader(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "x-access-token": environment.accessToken
    });
    return headers;
  }

  getAllItems(search?: string, query?: Record<string, unknown>) {
    let url = `${environment.apiUrl}/${this.route}`;
    return this.http.get<WalletInterfaceReading[]>(url, {
      headers: this.getHeader()
    });
  }

  usersWithoutWallet(search?: string, query?: Record<string, unknown>) {
    let url = `${environment.apiUrl}/${this.route}/users-without-wallet`;
    return this.http.get<UsersWithoutWalletInterface[]>(url, {
      headers: this.getHeader()
    });
  }

  findItem(id: string): Observable<WalletInterfaceReading>{
    return this.http.get<WalletInterfaceReading>(`${environment.apiUrl}/${this.route}/${id}`, {
      headers: this.getHeader()
    });
  }

  createItem(props: WalletInterfaceCreate): Observable<WalletInterfaceCreate> {
    return this.http.post<WalletInterfaceCreate>(`${environment.apiUrl}/${this.route}`, props, {
      headers: this.getHeader()
    });
  }

  updateItem(id: string, props: { name: string, clientIds: string[] }): Observable<WalletInterfaceCreate> {
    return this.http.put<WalletInterfaceCreate>(`${environment.apiUrl}/${this.route}/${id}`, props, {
      headers: this.getHeader()
    });
  }

  deleteItem(id: string): Observable<WalletInterfaceCreate> {
    return this.http.delete<WalletInterfaceCreate>(`${environment.apiUrl}/${this.route}/${id}`, {
      headers: this.getHeader()
    });
  }
}
