<div class="itemContainer">
    <fast-navigation
        [fastNavigationData]="fastNavigationData"
    ></fast-navigation>

    <div>
        <h2>Edição Carteira</h2>

        <div class="loading text-center mt-5" *ngIf="loading">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    
        <div class="contentBox" *ngIf="!loading">    
            <div class="header">
                <div class="img">
                    <img 
                        *ngIf="!wallet.avatar" 
                        src="../../../assets/img/profile-pic.png"  
                        alt="Foto de Perfil" 
                    />

                    <img 
                        *ngIf="wallet.avatar" 
                        [src]="wallet.avatar" 
                        alt="Foto de Perfil" 
                    />

                    <div class="planned-infos" [formGroup]="WalletForm">
                        <div class="container-name">
                            <h3 *ngIf="!editName">{{ wallet.name }}</h3>

                            <div class="input-group" *ngIf="editName">
                                <input 
                                    formControlName="name" 
                                    type="text" 
                                    class="form-control"
                                    placeholder="Nome da carteira"
                                />
                            </div>
                              

                            <span (click)="editNameField()" class='material-icons md-48'>edit</span>
                        </div>

                        <h3>{{ wallet.userName }}</h3>
                    </div>
                </div>

                <div class="options">
                    <button 
                        (click)="removeItem(wallet.id!)" 
                        [disabled]="loading"
                        type="button" 
                        class="btn btn-danger"
                    >
                        Remover Carteira
                    </button>

                    <button 
                        type="button" 
                        (click)="updateItem()" 
                        class="btn btn-success"
                        [disabled]="loading"
                    >
                        Atualizar
                    </button>
                </div>

            </div>

            <div class="tableData">
                <div class="options">
                    <button 
                        type="button" 
                        (click)="getOutletModal()" 
                        class="btn btn-primary"
                    >
                        Adicionar Cliente
                    </button>
                </div>

                <div *ngIf="wallet.clientList.length">
                    <table>
                        <tr>    
                            <th (click)="sortItems('cnpj')">
                                CNPJ
                                <img 
                                    src="../../../assets/icons/arrow-table.svg" 
                                    alt="ícone de seta para ordenar tabela"
                                    [class.sortIcon]="!itemSort.cnpj.sort"
                                />
                            </th>

                            <th (click)="sortItems('cnpj')">
                                Nome Fantasia
                                <img 
                                    src="../../../assets/icons/arrow-table.svg" 
                                    alt="ícone de seta para ordenar tabela"
                                    [class.sortIcon]="!itemSort.cnpj.sort" 
                                />
                            </th>

                            <th (click)="sortItems('cnpj')">
                                Razão Social
                                <img 
                                    src="../../../assets/icons/arrow-table.svg" 
                                    alt="ícone de seta para ordenar tabela"
                                    [class.sortIcon]="!itemSort.cnpj.sort" 
                                />
                            </th>
    
                            <th></th>
                        </tr>
                        <ng-container>
                            <tr *ngFor="let item of wallet.clientList | paginate: { id:'paginate_wallet', itemsPerPage: 10, currentPage: page }">
                                
                                <td>{{ item.cnpj }}</td>
                                <td>{{ item.tradeName }}</td>
                                <td>{{ item.companyName }}</td>
                                <td>
                                    <button 
                                        title="Deletar"
                                        class="btn btn-danger"
                                        (click)="removeClient(item.id!)"
                                    >
                                        <span class="material-icons md-48 whitecolor">delete</span>
                                    </button>                           
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                    <div class="finishTable">
                        <h3>Total: {{ wallet.clientList.length }}</h3>
                        <div class="paginator">
                            <div class="paginator">
                                <pagination-controls 
                                    id="paginate_wallet"
                                    nextLabel="Próximo" 
                                    (pageChange)="page = $event"
                                    previousLabel="Anterior"
                                >
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="text-align: center;" *ngIf="!wallet.clientList.length">
                    <h2>Nenhum dado encontrado</h2>
                </div>
            </div>    
        </div>
    </div>
</div>