<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nova Carteira</h4>
    <button 
      type="button" 
      class="btn-close" 
      aria-label="Close" 
      (click)="setCloseModal(false)"
    ></button>
</div>

<div class="modal-body" [formGroup]="WalletForm">
    <div class="item">
        <p>Nome da carteira</p>
    
        <div class="input-group mb-3">
            <input 
                type="text" 
                class="form-control" 
                placeholder="Digite o nome aqui"
                formControlName="name" 
            />
        </div>
    </div>

    <div class="item">
        <p>* Usuário</p>
    
        <div class="input-group mb-3">
            <select formControlName="userId" class="form-control" required>
              <option value="">Selecione um usuário</option>
              <option *ngFor="let item of usersNotWallets" [value]="item.userId">
                {{ item.userName }}
              </option>
            </select>
    
            <img
                src="../../../../../assets/icons/arrowDown.svg"
                alt="Seta pra baixo"
            />
        </div>

        <span *ngIf="onSubmit && !WalletForm.value.userId">Usuário é obrigatório</span>
    </div>

</div>  

<div class="modal-footer">
    <button 
        type="button" 
        class="btn btn-primary"
        (click)="save()"
        [disabled]="loading"
    >
        Cadastrar
    </button>
</div>