import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WalletInterfaceCreate, WalletInterfaceReading } from '../../wallet.interface';
import { WalletService } from '../../wallet.service';

@Component({
  selector: 'app-create-wallet-modal',
  templateUrl: './create-wallet-modal.component.html',
  styleUrls: ['./create-wallet-modal.component.scss']
})
export class CreateWalletModalComponent {

  constructor(
    private activeModal: NgbActiveModal,
    private walletService: WalletService
  ) { }

  @Input() usersNotWallets: WalletInterfaceReading[] = [];

  onSubmit: boolean = false;
  loading: boolean = false;

  WalletForm = new FormGroup({
    userId: new FormControl(""),
    name: new FormControl("")
  })

  save(){
    if(this.WalletForm.valid){
      this.loading = true;

      const data = {
        name: this.WalletForm.value.name,
        userId: this.WalletForm.value.userId,
        wallet_list: {}
      }

      this.walletService.createItem(data).subscribe(response => {
        this.loading = false;
        this.setCloseModal(true, response.id)
      })
    }

    if(!this.WalletForm.valid){
      this.onSubmit = true;
    }
  }

  setCloseModal(result: boolean, id?: string){
    this.WalletForm.reset();
    this.activeModal.close({
      success: result,
      id
    });
  }
}
