<nav class="navbar" clickOutside role="navigation" aria-label="main navigation" (clickOutside)="clickOutside()">
  <p>{{today | date:'dd/MM/yyyy'}}, {{today | date:'h:mm a'}}</p>

  <div *ngIf="isLoggedIn" data-test="navbar-menu" class="navbar-menu" (click)="menuActions()">
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="cargo">
          <p>{{ dataNavBar.personal_info.complete_name }}</p>
          <p>{{ dataNavBar.hierarchy }}</p>
        </div>
        <div class="containerImg" *ngIf="dataNavBar.personal_info.avatar_url">
          <img [src]="dataNavBar.personal_info.avatar_url" alt="Imagem usuário" id="imgUser">
        </div>
        <div class="containerUser" *ngIf="!dataNavBar.personal_info.avatar_url">
          <span>
            {{initials}}
          </span>
        </div>
        <span class="material-icons md-24 whitecolor">
          keyboard_arrow_down
        </span>
      </div>
    </div>
  </div>
</nav>

<nav class="menuPrincipal">
  <ul>
    <figure class="home">
      <img style="width: 70px; height: 50px;" src="../../../../assets/img/rar_logo.png" alt="Logo RAR" id="mMinalba">
      <h3>
        <img style="width: 180px;" src="../../../../assets/img/rar_logo.png" alt="Logo RAR">
      </h3>
    </figure>

    <li *ngFor="let route of routesList">
      <a [routerLink]="[route.link]">
        <span class="material-icons md-24 whitecolor">
          {{ route.icon }}
        </span>
        <h3>{{ route.text }}</h3>
      </a>
    </li>
  </ul>
</nav>

<div id="modal-notification" *ngIf="notification" class="notificationBox">
  <p id="titleperson">Olá, {{ dataNavBar.personal_info.complete_name }} !</p>
  <div class="content">
    <a (click)="menuActions()" [routerLink]="['/ferramentas']">
      <span class="material-icons md-24 darkcolor">
        build
      </span>
      <p>Ferramentas</p>
    </a>
    <button (click)="logOut()" class="btnLogout">
      <span class="material-icons md-24 darkcolor">
        logout
      </span>
      <p>Sair</p>
    </button>
  </div>
</div>