import { Component, Input, OnInit } from '@angular/core';
import { ExportService } from 'src/app/core/services/data_service/export/export.service';
import DownloadFile from 'src/app/utils/DownloadFile';
import { MakeExportData } from 'src/app/utils/MakeExportData';

@Component({
  selector: 'button-excel',
  templateUrl: './button-excel.component.html',
  styleUrls: ['./button-excel.component.scss']
})
export class ButtonExcelComponent {

  constructor(
    public exportService: ExportService
  ) { }

  loadingExport = false;

  @Input() modelExport = {}
  @Input() tableData = {}
  @Input() reportName = "";


  exportTableExcel() {

    this.loadingExport = true;

    this.modelExport = new MakeExportData().exportData(this.modelExport, this.tableData)

    this.exportService.exportTable(JSON.stringify(this.modelExport)).subscribe((response) => {

      this.loadingExport = false;
      return new DownloadFile().createFile(response, `${this.reportName} ${new Date().getTime()}.xlsx`);
    })
  }

}
