export const Calendar = {
    getDate: function (millis = new Date().getTime()) {
        return new Date(millis);
    },
    getDayOfWeek: function () {
        switch (this.getDate().getDay()) {
            case 0:
                return "sunday";
            case 1:
                return "monday";
            case 2:
                return "tuesday";
            case 3:
                return "wednesday";
            case 4:
                return "thursday";
            case 5:
                return "friday";
            case 6:
                return "saturday";
        }
    },
    getDayOfWeekFromDate: function (date) {
        switch (date.getDay()) {
            case 0:
                return "sunday";
            case 1:
                return "monday";
            case 2:
                return "tuesday";
            case 3:
                return "wednesday";
            case 4:
                return "thursday";
            case 5:
                return "friday";
            case 6:
                return "saturday";
        }
    },
    getFormattedDate: function (date) {
        return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}/${date.getFullYear()}`;
    },
    getFormattedDateReverse: function (date) {
        return `${date.getFullYear()}/${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}/${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    },
    getFormattedTime: function (date) {
        var h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        var m = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        var s = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

        return `${h}:${m}:${s}`;
    },
    getFormattedDateAndTime: function (date) {
        return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}/${date.getFullYear()} ${date.getHours() < 10 ?
            `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}:${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`;
    },
    getSimpleFormattedDateAndTime: function (date) {
        return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}${date.getFullYear()}${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
    },
    getDisplayTime: function (time) {
        if (time > 0) {
            var time_seconds = Math.floor((time / 1000) % 60);
            var time_minutes = Math.floor((time / 1000 / 60) % 60);
            var time_hours = Math.floor(time / 1000 / 60 / 60);

            return `${time_hours || 0}:${time_minutes || 0}:${time_seconds || 0}`;
        }

        return `${0}:${0}:${0}`;
    },
    compareTimes(time1, time2) {
        return Math.abs(time1 - time2);
    },
    createRangeDates(from, to, min_date = 0) {
        var from_date = from;//Iniciar na data de hoje
        var to_date = to//Finalizar na data de hoje
        var current_range = new Date(to_date.getTime());

        var range_months = [];
        var range_dates = [];

        //Precisamos criar o range baseado no filtro passado pela requisição
        while (current_range > from_date) {

            if (current_range.getDate() > min_date) {
                range_dates.push({
                    o_stamp: this.getFormattedDate(current_range),//Original stamp normal
                    stamp: this.getFormattedDateReverse(current_range),//Reverse stamp
                    day_of_week: current_range.getDay(),
                    time_stamp: current_range.getTime()
                })

                var month = (current_range.getMonth() + 1) < 10 ? `0${current_range.getMonth() + 1}` : current_range.getMonth() + 1;

                if (!range_months.includes(month)) {
                    range_months.push(month);
                }
            }

            current_range.setDate(current_range.getDate() - 1);


        }

        console.log(current_range.getDate(), " ", from_date.getDate());
        //Adicionar o ultimo range faltando na lista.
        if (current_range.getDate() >= from_date.getDate()) {
            range_dates.push({
                o_stamp: this.getFormattedDate(current_range),//Original stamp normal
                stamp: this.getFormattedDateReverse(current_range),//Reverse stamp
                day_of_week: current_range.getDay(),
                time_stamp: current_range.getTime()
            })

            var month = (current_range.getMonth() + 1) < 10 ? `0${current_range.getMonth() + 1}` : current_range.getMonth() + 1;

            if (!range_months.includes(month)) {
                range_months.push(month);
            }
        }

        //Se as datas forem iguais não será criado nenhum range
        //Criar um range de data para a data de hoje.
        if (range_dates.length == 0) {
            range_dates.push({
                o_stamp: this.getFormattedDate(current_range),//Original stamp normal
                stamp: this.getFormattedDateReverse(current_range),//Reverse stamp 
                day_of_week: current_range.getDay()
            })
        }

        return {
            range_dates: range_dates,
            range_months: range_months
        }
    },
    createRangeDatesProductivity(from, to, min_date = 0) {
        var from_date = from;//Iniciar na data de hoje
        var to_date = to//Finalizar na data de hoje
        var current_range = new Date(to_date.getTime());

        var range_months = [];
        var range_dates = [];

        //Precisamos criar o range baseado no filtro passado pela requisição
        while (current_range > from_date) {

            if (current_range.getDate() > min_date) {
                range_dates.push({
                    o_stamp: this.getFormattedDate(current_range),//Original stamp normal
                    stamp: this.getFormattedDateReverse(current_range),//Reverse stamp
                    day_of_week: current_range.getDay(),
                    time_stamp: current_range.getTime()
                })

                var month = (current_range.getMonth() + 1) < 10 ? `0${current_range.getMonth() + 1}` : current_range.getMonth() + 1;

                if (!range_months.includes(month)) {
                    range_months.push(month);
                }
            }

            current_range.setDate(current_range.getDate() - 1);


        }

        //Se as datas forem iguais não será criado nenhum range
        //Criar um range de data para a data de hoje.
        if (range_dates.length == 0) {
            range_dates.push({
                o_stamp: this.getFormattedDate(current_range),//Original stamp normal
                stamp: this.getFormattedDateReverse(current_range),//Reverse stamp 
                day_of_week: current_range.getDay()
            })
        }

        return {
            range_dates: range_dates,
            range_months: range_months
        }
    },
    convertToDate(date) { //Convert date DD/MM/YYYY to time stamp
        var split = date.split("/");

        return new Date(split[2] || 0, split[1] || 0, split[0]);
    },

    convertTimestampToDate(timestamp){ //Convert date timeStamp to DD/MM/YYYY
        const date = new Date(timestamp).toISOString();

        const year = date.slice(0, 4)
        const mounth = date.slice(5, 7)
        const day = date.slice(8, 10)

       return `${day}/${mounth}/${year}`
    }
}