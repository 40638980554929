<div class="itemContainer">
    <fast-navigation
        [fastNavigationData]="fastNavigationData"
    ></fast-navigation>

    <div>
        <h1>Carteiras</h1>
        
        <div class="filters">
            <div>
                <search-bar
                    [tableData]="walletList"
                    [tableDataFilter]="walletBackupList"
                    (tableDataUpdate)="changeSearch($event)"
                    placeholder="Digite aqui"
                ></search-bar>
            </div>

            <quantity-amount
                (changeAmountItem)="changeAmount($event)"
            ></quantity-amount>
    
            <div class="buttons">
                <button (click)="openCreateWalletModal()" [disabled]="loadingGetUsers">
                    <span class="material-icons md-48 whitecolor">add_circle</span>
                    Novo
                </button>
            </div>
        </div>
    </div>

    <ng-container>
        <div class="loading">
            <loading-circle
                [loading]="loadingTableItems"
            ></loading-circle>
        </div>
        
        <div class="loading" *ngIf="!loadingTableItems && !walletList.length">
            <h1>Sem registro com estas informações!</h1>
        </div>
        
        <div class="tableData" *ngIf="!loadingTableItems && walletList.length">
            <div class="container">
                <div *ngFor="let item of walletList | paginate: {itemsPerPage: pageControl.perPage, currentPage: pageControl.page, totalItems: walletList.length}">
                    <card-wallet-table
                        [item]="item"
                        [showUpdateItemButton]="true"
                        route="carteiras/edicao"
                    ></card-wallet-table>
                </div>
            </div>

            <div class="finishTable">
                <h3>Total: {{ walletList.length}}</h3>
                <div class="paginator">
                    <div class="paginator" id="paginator">
                        <pagination-controls 
                            (pageChange)="changePage($event)"
                            nextLabel="Próximo" 
                            previousLabel="Anterior"
                        >
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>