import { Component, Input } from '@angular/core';

@Component({
  selector: 'default-header-indicator',
  templateUrl: './default-header-indicator.component.html',
  styleUrls: ['./default-header-indicator.component.scss']
})
export class DefaultHeaderIndicatorComponent{

  constructor() { }

  @Input() title="";
  @Input() text="";
  @Input() loadingHeader;
}
