import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { IMarket, INetwork } from "./filter-component.interface";

@Component({
  selector: "filter-component",
  templateUrl: "./filter-component.component.html",
  styleUrls: ["./filter-component.component.scss"],
})
export class FilterComponentComponent implements OnInit {
  constructor() {}

  @Input() filterData: any = {};
  @Input() loadingFilters = false;
  @Input() includesProducts? = false;
  @Input() includesMarkets? = true;

  FormDefault = new FormGroup({
    search: new FormControl(""),
    directors: new FormControl(""),
    managers: new FormControl(""),
    coordinators: new FormControl(""),
    supervisors: new FormControl(""),
    promoters: new FormControl(""),
    supervisorCommercial: new FormControl(""),
    sellers: new FormControl(""),
    network: new FormControl(""),
    market: new FormControl(""),
    product: new FormControl(""),
    category: new FormControl(""),
    uf: new FormControl(""),
    periods: new FormControl(""),
    startDate: new FormControl(),
    finishDate: new FormControl(),
    dataPeriodo: new FormControl(""),
  });

  @Input() filterService;

  @Output() finaleParams = new EventEmitter<any>();

  strDate: String;
  endDate: String;
  dateDay: any;

  loadingTable = false;

  sub: Subscription[] = [];

  dateActually: any;

  dateFilter = false;
  periodoFilter = false;

  isEnabledCategory: boolean = false;
  productsBackup: any[] = [];
  isEnabledUf: boolean = false;
  marketsBack: IMarket[] = [];
  networksBack: INetwork[] = [];
  marketsFilteredByUf: IMarket[] = [];
  networksFilteredByMarkets: INetwork[] = [];

  listItensComplete: any = {
    hasItem: {
      markets: false,
      category: false,
      uf: false,
      networks: false,
      supervisors: false,
      promoters: false,
      commercial: false,
      coordinators: false,
      managers: false,
      sellers: false,
    },
  };

  ngOnInit(): void {
    if (this.filterData.filters.category) {
      this.isEnabledCategory = true;
    }

    if (this.filterData.filters.uf) {
      this.isEnabledUf = true;
    }

    this.dateActually = new Date();
    this.FormDefault.get("market").setValue("all");
    this.FormDefault.get("network").setValue("all");

    this.FormDefault.get("startDate").setValue(
      this.getFormattedDate(new Date())
    );
    this.FormDefault.get("finishDate").setValue(
      this.getFormattedDate(new Date())
    );
  }

  onSubmit() {
    this.loadingTable = true;
    this.onSubData();

    const select_period = this.FormDefault.get("periods").value;

    this.strDate = this.FormDefault.get("startDate").value;
    this.endDate = this.FormDefault.get("finishDate").value;
    this.dateDay = this.FormDefault.get("dataPeriodo").value;

    if (
      select_period === "periodos" &&
      (this.endDate < this.strDate || this.endDate == "" || this.strDate == "")
    ) {
      this.loadingTable = false;
      return;
    }

    const promoterForm = this.FormDefault.get("promoters").value;
    const sellerForm = this.FormDefault.get("sellers").value;
    const marketForm = this.FormDefault.get("market").value;
    const networkForm = this.FormDefault.get("network").value;
    const productForm = this.FormDefault.get("product").value;
    const categoryForm = this.FormDefault.get("category").value;
    const ufForm = this.FormDefault.get("uf").value;

    const params: any = {
      promoters: [
        ...this.filterData.filters.promoters,
        ...this.filterData.filters.sellers,
      ],
      from_date: new Date().setHours(0, 0, 0, 0),
      to_date: new Date().setUTCHours(23, 59, 59, 59),
      market: this.filterData.filters.markets,
      network: this.filterData.filters.networks,
    };

    if (promoterForm !== "") {
      params.promoters = [{ user_id: promoterForm }];
    }

    if (sellerForm !== "") {
      params.promoters = [{ user_id: sellerForm }];
    }

    if (marketForm != "") {
      params.market = [{ pdv_id: marketForm }];
    }

    if (networkForm != "") {
      if (networkForm !== "all" && marketForm === "all") {
        params.market = this.filterData.filters.markets;
      }

      params.network = [{ name: networkForm }];
    }

    if (ufForm != "") {
      params.network = this.filterData.filters.networks;

      if (marketForm == "all") {
        params.market = this.marketsFilteredByUf;
        // params.market = this.filterData.filters.markets;
      }

      if (networkForm != "all") {
        params.market = this.filterData.filters.markets;
      }
    }

    if (productForm != "") {
      params.product = this.formatedKeyword(productForm);
    }

    if (categoryForm != "") {
      params.category = this.formatedKeyword(categoryForm);
    }

    if (this.dateDay !== null && this.dateDay != "") {
      const dateNow = new Date();

      if (this.dateDay == "hoje") {
        params.from_date = new Date().setHours(0, 0, 0, 0);
        params.to_date = new Date(params.from_date).setUTCHours(23, 59, 59, 59);
      }
      if (this.dateDay == "ontem") {
        params.from_date = new Date(
          dateNow.setDate(dateNow.getDate() - 1)
        ).setHours(0, 0, 0, 0);
        params.to_date = new Date(params.from_date).setUTCHours(23, 59, 59, 59);
      }
      if (this.dateDay == "ultimosseven") {
        params.from_date = new Date(
          dateNow.setDate(dateNow.getDate() - 7)
        ).setHours(0, 0, 0, 0);
        params.to_date = new Date().setUTCHours(23, 59, 59, 59);
      }
      if (this.dateDay == "ultimosdias") {
        params.from_date = new Date(
          dateNow.setDate(dateNow.getDate() - 30)
        ).setHours(0, 0, 0, 0);
        params.to_date = new Date().setUTCHours(23, 59, 59, 59);
      }
    }

    if (
      this.strDate !== null &&
      this.endDate !== null &&
      this.dateDay === "" &&
      !this.dateDay
    ) {
      params.from_date = this.formatDate(this.strDate).setHours(0, 0, 0, 0);
      params.to_date = this.formatDate(this.endDate).setUTCHours(
        23,
        59,
        59,
        59
      );
    }

    this.dateDay = this.FormDefault.get("dataPeriodo").setValue("");

    return this.finaleParams.emit(params);
  }

  formatedKeyword(keyword: String) {
    return keyword
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  formatDate(value) {
    const splitValue = value.split("-");
    const date = new Date(
      parseInt(splitValue[0]),
      parseInt(splitValue[1]) - 1,
      parseInt(splitValue[2])
    );

    return date;
  }

  getFormattedDate(date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  }

  changeType() {
    const selectvalue = this.FormDefault.get("periods").value;

    this.strDate = "";
    this.endDate = "";

    if (selectvalue === "dia") {
      this.dateFilter = true;
      this.periodoFilter = false;
    } else if (selectvalue === "periodos") {
      this.dateFilter = false;
      this.periodoFilter = true;
    } else {
      this.periodoFilter = false;
      this.dateFilter = false;
    }
  }

  onChangeNetwork() {
    if (!this.listItensComplete.hasItem.markets) {
      this.listItensComplete.markets = this.filterData.filters.markets;
      this.listItensComplete.hasItem.markets = true;
    }

    const value = this.formatedKeyword(this.FormDefault.get("network").value);
    const selectUserID = this.FormDefault.get("promoters").value;

    if (value !== "all") {
      const listMarkets = [];

      for (let indexMarkets in this.listItensComplete.markets) {
        const marketData = this.listItensComplete.markets[indexMarkets];

        if (marketData.network) {
          const network = this.formatedKeyword(marketData.network);

          if (network === value) {
            listMarkets.push(marketData);
          }
        }
      }

      return (this.filterData.filters.markets = listMarkets);
    }

    if (!selectUserID) {
      return (this.filterData.filters.markets = this.listItensComplete.markets);
    }
  }

  onChangeCategory() {
    if (!this.productsBackup.length) {
      this.productsBackup = this.filterData.filters.products;
    }

    const value: string = this.FormDefault.get("category").value;

    if (value != "") {
      const categorySelect = this.filterData.filters.categories.find(
        (category) => category.name === value
      );

      const productsBycategory = this.productsBackup.filter(
        (product) => product.category == categorySelect.id
      );

      this.filterData.filters.products = productsBycategory;
    } else {
      this.FormDefault.get("category").setValue("");
      this.filterData.filters.products = this.productsBackup;
    }
  }

  onChangeUf() {
    if (!this.marketsBack.length) {
      this.marketsBack = this.filterData.filters.markets;
      this.networksBack = this.filterData.filters.networks;
    }

    console.log("marketsBack", this.marketsBack);
    console.log("networksBack", this.networksBack);

    const value: string = this.FormDefault.get("uf").value;

    if (value != "") {
      this.marketsFilteredByUf = this.marketsBack.filter((market) => {
        return market?.uf?.toLowerCase() === value.toLowerCase();
      });

      this.networksFilteredByMarkets = [
        ...new Set(
          this.marketsFilteredByUf?.map((market) => market.network.trim())
        ),
      ]
        .map((networkText) => {
          return this.networksBack.find((network) => {
            return (
              network?.name &&
              network?.name.trim().toLowerCase() === networkText.toLowerCase()
            );
          });
        })
        .filter((network) => typeof network != "undefined");

      this.filterData.filters.networks = this.networksFilteredByMarkets;
      this.filterData.filters.markets = this.marketsFilteredByUf;
    } else {
      this.FormDefault.get("market").setValue("");
      this.filterData.filters.markets = this.marketsBack;

      this.FormDefault.get("network").setValue("");
      this.filterData.filters.networks = this.networksBack;
    }
  }

  onChangePromoters() {
    const hasPromoters = this.listItensComplete.hasItem.promoters;
    const hasMarkets = this.listItensComplete.hasItem.markets;
    const hasNetwork = this.listItensComplete.hasItem.networks;

    if (!hasPromoters) {
      this.listItensComplete.promoters = this.filterData.filters.promoters;
      this.listItensComplete.hasItem.promoters = true;
    }

    if (!hasMarkets) {
      this.listItensComplete.markets = this.filterData.filters.markets;
      this.listItensComplete.hasItem.markets = true;
    }

    if (!hasNetwork) {
      this.listItensComplete.networks = this.filterData.filters.networks;
      this.listItensComplete.hasItem.networks = true;
    }

    const userID = this.FormDefault.get("promoters").value;

    if (userID) {
      const findPromoter = this.filterData.filters.promoters.find(
        (element) => element.user_id === userID
      );

      if (findPromoter) {
        const listMarkets = [];
        const listNetworks = [];

        for (let indexMarkets in this.listItensComplete.markets) {
          const marketData = this.listItensComplete.markets[indexMarkets];

          if (marketData.user_list.includes(userID)) {
            listMarkets.push(marketData);
          }
        }

        for (let indexMarket in listMarkets) {
          const marketData = listMarkets[indexMarket];
          const networkName = this.formatedKeyword(marketData.network);

          if (
            !listNetworks.find(
              (element) => this.formatedKeyword(element.name) === networkName
            )
          ) {
            const findNetworkData = this.listItensComplete.networks.find(
              (element) => this.formatedKeyword(element.name) === networkName
            );

            if (findNetworkData) {
              listNetworks.push(findNetworkData);
            }
          }
        }

        this.filterData.filters.networks = listNetworks;
        this.filterData.filters.markets = listMarkets;
      }
    } else {
      this.filterData.filters.promoters = this.listItensComplete.promoters;
      this.filterData.filters.markets = this.listItensComplete.markets;
      this.filterData.filters.networks = this.listItensComplete.networks;
    }
  }

  onChangeSellers() {
    const hasSellers = this.listItensComplete.hasItem.sellers;
    const hasMarkets = this.listItensComplete.hasItem.markets;
    const hasNetwork = this.listItensComplete.hasItem.networks;

    if (!hasSellers) {
      this.listItensComplete.sellers = this.filterData.filters.sellers;
      this.listItensComplete.hasItem.sellers = true;
    }

    if (!hasMarkets) {
      this.listItensComplete.markets = this.filterData.filters.markets;
      this.listItensComplete.hasItem.markets = true;
    }

    if (!hasNetwork) {
      this.listItensComplete.networks = this.filterData.filters.networks;
      this.listItensComplete.hasItem.networks = true;
    }

    const userID = this.FormDefault.get("sellers").value;

    if (userID) {
      const findSeller = this.filterData.filters.sellers.find(
        (element) => element.user_id === userID
      );

      if (findSeller) {
        const listMarkets = [];
        const listNetworks = [];

        for (let indexMarkets in this.listItensComplete.markets) {
          const marketData = this.listItensComplete.markets[indexMarkets];

          if (marketData.user_list.includes(userID)) {
            listMarkets.push(marketData);
          }
        }

        for (let indexMarket in listMarkets) {
          const marketData = listMarkets[indexMarket];
          const networkName = this.formatedKeyword(marketData.network);

          if (
            !listNetworks.find(
              (element) => this.formatedKeyword(element.name) === networkName
            )
          ) {
            const findNetworkData = this.listItensComplete.networks.find(
              (element) => this.formatedKeyword(element.name) === networkName
            );

            if (findNetworkData) {
              listNetworks.push(findNetworkData);
            }
          }
        }

        this.filterData.filters.networks = listNetworks;
        this.filterData.filters.markets = listMarkets;
      }
    } else {
      this.filterData.filters.sellers = this.listItensComplete.sellers;
      this.filterData.filters.markets = this.listItensComplete.markets;
      this.filterData.filters.networks = this.listItensComplete.networks;
    }
  }

  onChangeHierarchies(hiear: string, target: string) {
    if (!this.listItensComplete.hasItem[hiear]) {
      this.listItensComplete[hiear] = this.filterData.filters[hiear];
      this.listItensComplete["supervisors"] =
        this.filterData.filters.supervisors;
      this.listItensComplete["promoters"] = this.filterData.filters.promoters;
      this.listItensComplete.hasItem[hiear] = true;
    }

    if (!this.listItensComplete.hasItem[target]) {
      this.listItensComplete[target] = this.filterData.filters[target];
      this.listItensComplete.hasItem[target] = true;
    }

    const managerID = this.FormDefault.get(hiear).value;

    if (managerID) {
      this.filterData.filters[target] = this.listItensComplete[target];
      const listUsers = this.getListUsersManagers(target, managerID);

      if (listUsers.length) {
        if (target === "supervisors") {
          this.setListByListUsersManagers(listUsers, "promoters");
        }

        if (target === "sellers") {
          this.filterData.filters.promoters = [];
        }

        return (this.filterData.filters[target] = listUsers);
      }
    }

    if (!managerID) {
      switch (hiear) {
        case "coordinators":
          this.filterData.filters.promoters = this.listItensComplete.promoters;
          this.filterData.filters.supervisors =
            this.listItensComplete.supervisors;
          break;

        case "supervisorCommercial":
          this.filterData.filters.supervisors =
            this.listItensComplete.supervisors;
          this.filterData.filters.promoters = this.listItensComplete.promoters;
          break;
      }
    }

    return (this.filterData.filters[target] = this.listItensComplete[target]);
  }

  setListByListUsersManagers(listUsers: any[], targetHiears: string) {
    const listUsersTargets = [];

    for (let indexUser in listUsers) {
      const userData = listUsers[indexUser];

      const users = this.getListUsersManagers(targetHiears, userData.user_id);

      listUsersTargets.push(...users);
      this.filterData.filters[targetHiears] = users;
    }

    return listUsersTargets;
  }

  getListUsersManagers(target: string, managerId: string) {
    if (!this.listItensComplete.hasItem[target]) {
      this.listItensComplete[target] = this.filterData.filters[target];

      this.listItensComplete.hasItem[target] = true;
    }

    const listPromoters = [];

    for (let indexPromoter in this.filterData.filters[target]) {
      const promoterData = this.filterData.filters[target][indexPromoter];
      const findManager = promoterData.managers.find(
        (element) => element.user_id === managerId
      );

      if (findManager) {
        listPromoters.push(promoterData);
      }
    }

    return listPromoters;
  }

  getPDVsIDs(listMarkets) {
    const listIDs = [];

    for (let indexPDV in listMarkets) {
      const market = listMarkets[indexPDV];

      listIDs.push(market.pdv_id);
    }

    return listIDs;
  }

  onSubData() {
    this.sub.forEach((s) => s.unsubscribe());
  }
}
