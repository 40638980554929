import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterSearch } from 'src/app/utils/searchItens';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() tableData: any[] = [];
  @Input() tableDataFilter: any[] = [];
  @Input() placeholder: String;

  @Output() tableDataUpdate = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  searchItens(input: string) {
    if (!input) {
      return this.tableData = this.tableDataFilter;
    }

    this.tableData = this.tableDataFilter;
    this.tableData = new FilterSearch().search(this.tableDataFilter, input, "search");

    this.tableDataUpdate.emit({
      tableData: this.tableData,
      tableDataFilter: this.tableDataFilter
    })
  }
}
