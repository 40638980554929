import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { WalletRoutingModule } from "./wallet-routing.module";
import { WalletPage } from "./wallet.page";
import { WalletService } from "./wallet.service";
import { CardWalletTableComponent } from './components/card-wallet-table/card-wallet-table.component';
import { NgxPaginationModule } from "ngx-pagination";
import { CreateWalletModalComponent } from './components/create-wallet-modal/create-wallet-modal.component';
import { ReactiveFormsModule } from "@angular/forms";
import { RemoveWalletComponent } from './components/remove-wallet/remove-wallet.component';
import { FastNavigationModule } from "src/app/components/fast-navigation/fast-navigation.module";
import { ComponentsDefaultModule } from "src/app/components/components.module";

@NgModule({
  declarations: [WalletPage, CardWalletTableComponent, CreateWalletModalComponent, RemoveWalletComponent],
  imports: [
    WalletRoutingModule,
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FastNavigationModule,
    ComponentsDefaultModule
  ],
  exports: [
    WalletPage,
    CardWalletTableComponent
  ],
  providers: [
    WalletService,
  ]
})
export class WalletModule { }
