import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Util } from 'src/app/shared/util';
import { UsersService } from '../../services/users/users.service';

//import  from "../../../../assets/img/profile-pic.png"
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public user: any;
  public cookieValue;
  isLoggedIn: boolean = false;
  notification: boolean = false;
  imgUser: string;
  initials: string;
  today: number = Date.now();

  dataNavBar = {
    personal_info: {
      complete_name: "",
      avatar_url:""
    },
    hierarchy: "",
  }

  constructor(
    private router: Router,
    private usersService: UsersService,
    private cookieService: CookieService
  ) {  }


  routesList = [
    { link: "/home", icon: "home", text: "Gestão" },
    { link: "/produtividade", icon: "assignment_turned_in", text: "Produtividade" },
    { link: "/visitas", icon: "search", text: "Visitas" },
    { link: "/rotina-de-trabalho", icon: "work", text: "Rotina de Trabalho" },
    { link: "/preco", icon: "monetization_on", text: "Preços" },
    { link: "/concorrentes", icon: "sports_mma", text: "Concorrência" },
    { link: "/rupturas", icon: "broken_image", text: "Rupturas" },
    { link: "/validade", icon: "event_busy", text: "Data Crítica" },
    { link: "/estoque", icon: "date_range", text: "Estoque" },
    { link: "/manipulacao", icon: "assignment", text: "Manipulação" },
    { link: "/mpdv", icon: "assignment", text: "Merchanding" },
    { link: "/cartao-ponto", icon: "schedule", text: "Cartão de Ponto" },
  ]

  ngOnInit(): void {
    const dataLocalStorage = JSON.parse(localStorage.getItem('personal_info'));
    this.isLoggedIn = Util.verifyLogged();
    this.dataNavBar.personal_info.complete_name = dataLocalStorage.personal_info.complete_name;
    this.dataNavBar.hierarchy = dataLocalStorage.hierarchy;
    this.dataNavBar.personal_info.avatar_url = "https://firebasestorage.googleapis.com/v0/b/rarsmartperformance.appspot.com/o/default_avatar.png?alt=media&token=59bad94e-106d-4daa-a446-a4e4dd4a43d4";
    this.imgUser = dataLocalStorage.personal_info.avatar_url;
    this.getInitials();
  }


  getInitials(): void {
    const initial1 = this.dataNavBar.personal_info.complete_name[0];
    const initial2 = this.dataNavBar.personal_info.complete_name[1];
    this.initials = initial1 + initial2;
  }

  logOut(){
    localStorage.removeItem('workgroup');
    localStorage.removeItem('personal_info');
    localStorage.removeItem('detailvisits');
    this.cookieService.deleteAll();

    this.usersService.logout().subscribe((response)=>{
    });

    this.usersService.setIsLogged(false);

    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 500);
  }

  menuActions(){
    this.notification = !this.notification;
  }

  clickOutside(){
    this.notification = false;
  }
}
