import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WalletInterfaceReading } from '../../wallet.interface';

@Component({
  selector: 'card-wallet-table',
  templateUrl: './card-wallet-table.component.html',
  styleUrls: ['./card-wallet-table.component.scss']
})
export class CardWalletTableComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  @Input() item: any = {
    avatar: "",
    name: "",
    userName: "",
    total: [],
    userId: "",
    id: ""
  };

  @Input() showUpdateItemButton: boolean = true;
  @Input() route: string = "";

  ngOnInit(): void {
    if(!this.item.total){
      this.item.total = [];
    }
  }

  navigate(id: string | undefined){
    if(id){
      this.router.navigate([`ferramentas/${this.route}`, id])
    }
  }
}
