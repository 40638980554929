import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-headers-indicators-colors',
  templateUrl: './headers-indicators-colors.component.html',
  styleUrls: ['./headers-indicators-colors.component.scss']
})
export class HeadersIndicatorsColorsComponent implements OnInit, OnChanges {

  @Input() title = ''
  @Input() value = ''
  @Input() title2 = ''
  @Input() value2 = ''
  @Input() title3 = ''
  @Input() value3 = ''
  @Input() title4 = ''
  @Input() value4 = ''
  @Input() loading: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.loading = changes.loading.currentValue
  }  
}
