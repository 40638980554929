import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { WalletService } from "../../wallet.service";
import { UpdateWalletRoutingModule } from "./update-wallet-routing.module";
import { UpdateWalletPage } from "./update-wallet.page";
import { UpdateWalletService } from "./update-wallet.service";
import { ModalAddClientComponent } from './components/modal-add-client/modal-add-client.component';
import { FastNavigationModule } from "src/app/components/fast-navigation/fast-navigation.module";
import { ComponentsDefaultModule } from "src/app/components/components.module";

@NgModule({
  declarations: [UpdateWalletPage, ModalAddClientComponent],
  imports: [
    UpdateWalletRoutingModule,
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FastNavigationModule,
    ComponentsDefaultModule
  ],
  exports: [UpdateWalletPage],
  providers: [UpdateWalletService, WalletService]
})
export class UpdateWalletModule { }
