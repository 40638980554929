import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateWalletModalComponent } from "./components/create-wallet-modal/create-wallet-modal.component";
import { UsersWithoutWalletInterface, WalletInterfaceReading } from "./wallet.interface";
import { WalletService } from "./wallet.service";

@Component({
	selector: "app-wallet",
	templateUrl: "./wallet.page.html",
	styleUrls: ["./wallet.page.scss"]
})
export class WalletPage implements OnInit {

	constructor(
    private walletService: WalletService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  fastNavigationData = [
    {
      path: '/home',
      name: 'Home',
    },
    {
      path: '/ferramentas',
      name: 'Ferramentas',
    },
    {
      path: '/ferramentas/cateiras',
      name: 'Carteira',
    }
  ];

  loadingTableItems: boolean = false;
  showCreateItemButton: boolean = false;
  showUpdateItemButton: boolean = false;
  query: Record<string, string> = {};
  search: string = "";
  usersNotWallets: UsersWithoutWalletInterface[] = [];
  quantityItems = [12, 25, 50, 100];  
  walletList: WalletInterfaceReading[] = [];
  walletBackupList: WalletInterfaceReading[] = [];
  loadingGetUsers: boolean = false;
  pageControl = {
    page: 1,
    perPage: 12
  }

  ngOnInit(): void {
    this.getItems();
    this.getUsersWallet();
  }

  getItems(){
    this.loadingTableItems = true;

    this.walletService.getAllItems().subscribe(response => {
      this.walletList = response;
      this.walletBackupList = response;
      this.loadingTableItems = false;
    });
  }

  getUsersWallet(){
    this.loadingGetUsers = true;
    this.walletService.usersWithoutWallet().subscribe(response => {
      if(response && response.length){
        this.usersNotWallets.push(...response);
      }

      this.loadingGetUsers = false;
    })
  } 

  changePage(page: number){
    this.pageControl.page = page;
  }

  changeAmount(number: string){
    this.pageControl.perPage = parseInt(number);
  }

  openCreateWalletModal(){
    const modalRef = this.modalService.open(CreateWalletModalComponent, {
      size: "lg"
    });

    modalRef.componentInstance.usersNotWallets = this.usersNotWallets;
    
    modalRef.result.then(result => {
      if(result.success){
        this.router.navigate(["/ferramentas/carteiras/edicao", result.id])
      }
    })
  }

  changeSearch(walletSearch: any){
    this.walletList = walletSearch.tableData;
  }
}
