<div class="indicators-container" >
    <div class="blue-indicator">
        <div class="header">
            <p>{{ title }}</p>
        </div>
        <div class="body">
            <p class="indicator-value" id="actived_promoters_value">
                {{ value }}
            </p>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="blue-indicator">
        <div class="header">
            <p>{{ title2 }}</p>
        </div>
        <div class="body">
            <p class="indicator-value" id="actived_promoters_value">
                {{ value2 }}
            </p>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="blue-indicator">
        <div class="header">
            <p>{{ title3 }}</p>
        </div>
        <div class="body">
            <p class="indicator-value" id="actived_promoters_value">
                {{ value3 }}
            </p>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="blue-indicator">
        <div class="header">
            <p>{{ title4 }}</p>
        </div>
        <div class="body">
            <p class="indicator-value" id="actived_promoters_value">
                {{ value4 }}
            </p>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>