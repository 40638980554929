<div class="blue-indicator" item="item">
    <div class="header">
        <p>{{ title }}</p>
    </div>
    <div class="body">
        <p class="indicator-value" id="actived_promoters_value" *ngIf="!loadingHeader">
            {{ text }}</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="loadingHeader">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>